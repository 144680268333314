* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
.navigation-menu {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navigation-menu li {
  margin: 0px 20px;
}

.navigation-menu li a {
  text-decoration: none;
  color: white;
  transition: all 300ms;
}
.navigation-menu li a:hover {
  color: red;
  transition: all 300ms;
}
.mobile-navigation-menu {
  list-style: none;
}
.mobile-navigation-menu li {
  margin-top: 20px;
}
.active {
  border-bottom: 1px solid red;
}
.mobile-navigation-menu li a {
  text-decoration: none;
  font-size: 1.4rem;
  color: black;
}
.home {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.headerContainer {
  width: auto;
  margin-left: 30px;
}
.home .headerContainer h1 {
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 70px;
  color: black;
}
.home .headerContainer p {
  margin-top: 15px;
  padding: 0;
  font-size: 40px;
  font-weight: lighter;
  color: black;
}
.home .headerContainer button {
  margin-top: 20px;
  border: none;
  border-radius: 5px;

  height: 50px;
  width: 180px;
  text-decoration: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  background: black;
}

/* mobile view */

@media only screen and (max-width: 600px) {
  .home {
    justify-content: center;
    align-items: center;
  }
  .headerContainer {
    margin-left: 0;
    border-radius: 10px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;
    color: wheat;
  }
  .home .headerContainer h1 {
    margin: 15px 0px;
    height: 30px;
    font-size: 40px;
    color: white;
  }
  .home .headerContainer p {
    font-size: 20px;

    color: white;
  }
  .home .headerContainer button {
    color: black;
    background-color: white;
    margin-bottom: 30px;
  }
}
